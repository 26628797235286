import Icons from "icons/sidebar";
import CategoryIcon from "@mui/icons-material/Category";

const index = [
  {
    title: "My products",
    Icon: CategoryIcon,
    path: "/dashboard",
  },
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard/default",
  },
  {
    title: "User Profile",
    Icon: Icons.UserProfileIcon,
    path: "/dashboard/user-profile",
  },
  {
    title: "User Grid",
    Icon: Icons.UserGridIcon,
    path: "/dashboard/user-grid",
  },
  {
    title: "User List",
    Icon: Icons.UserManagementIcon,
    path: "/dashboard/user-list",
  },
  {
    title: "Add user",
    Icon: Icons.AddUserIcon,
    path: "/dashboard/add-user",
  },
  {
    title: "Login",
    Icon: Icons.LoginIcon,
    path: "/login",
  },
  {
    title: "Register",
    Icon: Icons.SessionsIcon,
    path: "/Register",
  },
];

export default index;
